import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Text } from 'rebass';
import Helmet from 'react-helmet';
import {
  display,
  flexDirection,
  justifyContent,
  alignItems,
  height,
  overflow,
} from 'styled-system';
import styled, { createGlobalStyle } from 'styled-components';

import { CenterContentTemplate, Typography } from '@oca/ui';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { OCALogo, AvisaLogo, Halcyon } from '@oca/icons';

import heroImage from '../assets/hero.jpg';
import officeImage from '../assets/office-image.jpg';

const documentTitlesMap = {
  oca: t`OCA - Olympic Council of Asia`,
  avisa: t`Avisa Tech`,
  halcyon: t`Halcyon Incubator`,
};

const titleTemplatesMap = {
  oca: '%s | OCA',
  avisa: '%s | Avisa Tech',
  halcyon: '%s | Halcyon Incubator',
};

const organizationFullNamesMap = {
  oca: t`Olympic Council of Asia`,
  avisa: 'Avisa Technologies',
  halcyon: 'Halcyon Incubator',
};

const logosMap = {
  oca: OCALogo,
  avisa: AvisaLogo,
  halcyon: Halcyon,
};

const bgImagesMap = {
  oca: heroImage,
  avisa: officeImage,
};

const ORG = process.env.REACT_APP_ORGANIZATION;

const Logo = logosMap[ORG];

export function AuthPageTemplate({ heading, children, title }) {
  const defaultTitle = i18n._(documentTitlesMap[ORG]);

  return (
    <CenterContentTemplate>
      <Helmet
        defaultTitle={defaultTitle}
        titleTemplate={titleTemplatesMap[ORG]}
      >
        {title && <title>{title}</title>}
      </Helmet>
      <GlobalStyles />
      <FlexCard
        width="800px"
        boxShadow={1}
        bg="white"
        borderRadius={8}
        overflow="hidden"
      >
        <Box width={2 / 5} px={4} pt={5} pb={3}>
          <Typography variant="h1" fontWeight={400} paragraph>
            <Text as="span" color="secondary">
              {ORG && ORG.toUpperCase()}
            </Text>{' '}
            Digital Platform
          </Typography>
          {heading}
          {children}
        </Box>
        <HeroBlock width={3 / 5}>
          <FlexCard
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            height="100%"
          >
            <Box marginBottom={2}>
              <Logo fontSize={250} color="white" />
            </Box>
            <Typography variant="h1" fontWeight={300} color="white">
              {i18n._(organizationFullNamesMap[ORG]) || ''}
            </Typography>
          </FlexCard>
        </HeroBlock>
      </FlexCard>
    </CenterContentTemplate>
  );
}

AuthPageTemplate.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  title: PropTypes.string,
};

AuthPageTemplate.defaultProps = {
  children: null,
  title: null,
  heading: null,
};

const GlobalStyles = createGlobalStyle`
  #root { 
    background: linear-gradient(
      45deg,
      rgba(0, 117, 244, 1) 0%,
      rgba(0, 179, 255, 1) 100%
    )
  }
`;

const FlexCard = styled(Card)`
  ${display};
  ${flexDirection}
  ${justifyContent};
  ${alignItems};
  ${height};
  ${overflow};
`;

FlexCard.defaultProps = {
  display: 'flex',
};

// eslint-disable-next-line react/prop-types
const HeroBlock = ({ width, children }) => (
  <FlexCard
    display="block"
    width={width}
    backgroundImage={`url(${bgImagesMap[ORG] || bgImagesMap.avisa})`}
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
  >
    {children}
  </FlexCard>
);
