import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { space } from 'styled-system';

import { createTransition } from '../theme';

/**
 * @typedef {import('react').ReactNode} ReactNode
 * @typedef {HTMLInputElement & import('styled-system').SpaceProps & { startAdornment: ReactNode, endAdornment: ReactNode, error: Boolean, hideIndicator: Boolean }} InputProps
 *
 */

export const Input = React.forwardRef(
  /**
   * @param {InputProps} props
   */
  (props, ref) => {
    const {
      startAdornment,
      endAdornment,
      error,
      disabled,
      hideIndicator,
      fullWidth,
      ...rest
    } = props;

    return (
      <Wrapper
        error={error}
        disabled={disabled}
        paddingLeft={startAdornment ? 2 : 0}
        paddingRight={endAdornment ? 1 : 0}
        width={fullWidth ? '100%' : undefined}
      >
        {startAdornment}
        <InputBase
          py={2}
          paddingLeft={startAdornment ? 0 : 3}
          paddingRight={endAdornment ? 0 : 3}
          disabled={disabled}
          error={error}
          {...rest}
          ref={ref}
        />
        {endAdornment}
        {!hideIndicator && <FocusIndicator error={error} />}
      </Wrapper>
    );
  },
);

Input.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  hideIndicator: PropTypes.bool,
  fullWidth: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  startAdornment: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  endAdornment: PropTypes.node,
  autoComplete: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
};

Input.defaultProps = {
  error: false,
  disabled: false,
  hideIndicator: false,
  autoComplete: 'off',
};

const FocusIndicator = styled.div`
  position: absolute;
  top: 0;
  left: -1px;
  width: 4px;
  height: 100%;
  transform: scaleX(0);
  transition: ${createTransition('transform')};
  transform-origin: left;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const InputBase = styled.input`
  height: 36px;
  box-sizing: border-box;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes[1]}px;
  width: 100%;
  border: 0;
  color: ${props => props.theme.colors.text};
  transition: border-color 100ms linear;
  border-radius: 4px;
  -webkit-tap-highlight-color: transparent;
  :focus {
    & ~ ${FocusIndicator} {
      transform: scaleX(1);
      background-color: ${getFocusColor};
    }
  }
  ::-webkit-input-placeholder {
    color: ${props => props.theme.colors.disabled};
  }
  ::-moz-placeholder {
    color: ${props => props.theme.colors.disabled};
  }
  :-ms-input-placeholder {
    color: ${props => props.theme.colors.disabled};
  }
  :-moz-placeholder {
    color: ${props => props.theme.colors.disabled};
    opacity: 1;
  }
  ${space}
`;

const Wrapper = styled(Box)`
  position: relative;
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  border-color: ${props =>
    (props.error && props.theme.colors.error) ||
    (props.disabled && props.theme.colors.disabled) ||
    'rgba(0, 0, 0, 0.23)'};
  border-radius: 4px;

  ${InputBase}:focus & {
    border-color: ${getFocusColor};
  }
`;

function getFocusColor(props) {
  return props.error ? props.theme.colors.error : props.theme.colors.primary;
}
