export { Add } from './add';
export { AddCircleOutlined } from './add-circle-outlined';
export { AddDocument } from './add-document';
export { AddFolder } from './add-folder';
export { Alert } from './alert';
export { ArrowBack } from './arrow-back';
export { ArrowForward } from './arrow-forward';
export { ArrowKeyboardRight } from './arrow-keyboard-right';
export { AddPerson } from './add-person';
export { ArrowRight } from './arrow-right';
export { ArrowRight2 } from './arrow-right-2';
export { ArrowRight3 } from './arrow-right-3';
export { ArrowRightCircle } from './arrow-right-circle';
export { ArrowLeft } from './arrow-left';
export { ArrowLeft2 } from './arrow-left2';
export { Attachment } from './attachment';
export { AvisaLogo } from './avisa-logo';
export { AvisaLogoColored } from './avisa-logo-colored';
export { Bell } from './bell';
export { BrokenImage } from './broken-image';
export { Calendar } from './calendar';
export { Camera } from './camera';
export { Card } from './card';
export { CautionCircleOutlined } from './caution-circle-outlined';
export { Checkbox } from './checkbox';
export { CheckboxIndeterminate } from './checkbox-indeterminate';
export { CheckedCheckbox } from './checked-checkbox';
export { CheckedRadioButton } from './checked-radio-button';
export { Check } from './check';
export { CloudSun } from './cloud-sun';
export { Clear } from './clear';
export { Clock } from './clock';
export { Clock2 } from './clock2';
export { Close } from './close';
export { CloseX } from './close-x';
export { Crumbs } from './crumbs';
export { Delete } from './delete';
export { DoubleCheck } from './double-check';
export { Download } from './download';
export { Earth } from './earth';
export { FilledClose } from './filled-close';
export { FingerPrint } from './fingerprint';
export { LightClose } from './light-close';
export { Cloud } from './cloud';
export { Comments } from './comments';
export { Dashboard } from './dashboard';
export { DefaultAvatar } from './default-avatar';
export { DeviceUnknown } from './device-unknown';
export { Documents } from './documents';
export { Email } from './email';
export { Edit } from './edit';
export { File } from './file';
export { FileCheck } from './file-check';
export { FileEdit } from './file-edit';
export { Folder } from './folder';
export { Forward } from './forward';
export { Hamburger } from './hamburger';
export { HamburgerSmall } from './hamburger-small';
export { Halcyon } from './halcyon';
export { History } from './history';
export { Image } from './image';
export { Key } from './key';
export { Link } from './link';
export { Location } from './location';
export { Logout } from './logout';
export { Meeting } from './meeting';
export { MenuDots } from './menu-dots';
export { Message } from './message';
export { Notification } from './notification';
export { OCALogo } from './o-c-a-logo';
export { OCALogoColored } from './o-c-a-colored-logo';
export { PencilRuler } from './pencil-ruler';
export { Pencil } from './pencil';
export { PersonCheck } from './person-check';
export { PersonDelete } from './person-delete';
export { Person } from './person';
export { Print } from './print';
export { RadioButton } from './radio-button';
export { Rain } from './rain';
export { Refresh } from './refresh';
export { Send } from './send';
export { Share } from './share';
export { StarDefault } from './star-default';
export { StarChecked } from './star-checked';
export { Sun } from './sun';
export { Search } from './search';
export { TimeClock } from './time-clock';
export { TripleDotsHorizontal } from './triple-dots-horizontal';
export { Umbrella } from './umbrella';
export { UnwrapFolder } from './unwrap-folder';
export { Upload } from './upload';
export { Weather } from './weather';
export { Wind } from './wind';
export { Error } from './error';
export { Info } from './info';

// FILE EXTENSTONS ICONS
export { Aiff } from './aiff';
export { Avi } from './avi';
export { Bmp } from './bmp';
export { Cad } from './cad';
export { Csv } from './csv';
export { Jpg } from './jpg';
export { Dbf } from './dbf';
export { Dng } from './dng';
export { Doc } from './doc';
export { Eps } from './eps';
export { Flac } from './flac';
export { Flv } from './flv';
export { Html } from './html';
export { Gif } from './gif';
export { Mov } from './mov';
export { Mp3 } from './mp3';
export { Mp4 } from './mp4';
export { Mxf } from './mxf';
export { Pdf } from './pdf';
export { Png } from './png';
export { Ppt } from './ppt';
export { Svg } from './svg';
export { Xls } from './xls';
export { Rar } from './rar';
export { Tab } from './tab';
export { Tiff } from './tiff';
export { Txt } from './txt';
export { Xml } from './xml';
export { Zip } from './zip';
export { Wav } from './wav';
export { UnknownFile } from './unknown-file';
