import { fetcher } from '@features/common';

const getConfig = (cb, source) => {
  const cancelToken = source.token;
  return {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (cb) cb(percentCompleted);
    },
    cancelToken,
  };
};

export function fetchDirsDocsList() {
  return fetcher.get('/api/v1/doc-composed/');
}

export function fetchCompanyDirsDocsList() {
  return fetcher.get('/api/v1/doc-company/');
}

const endpoint = '/api/v1/documents/';

export function fetchDocumentsList() {
  return fetcher.get(endpoint);
}

export function uploadDocument(document, cb, source) {
  return fetcher.post(endpoint, document, getConfig(cb, source));
}

export function editDocument(id, data) {
  return fetcher.patch(`${endpoint + id}/`, data);
}

export function deleteDocument(id) {
  return fetcher.delete(`${endpoint + id}/`);
}

export function downloadDocument(id) {
  return fetcher.get(`/api/v1/document-versions/${id}/media/`, {
    responseType: 'blob',
  });
}

// FAVORITED
export function editDocumentFavorited(id, data) {
  return fetcher.post(`${endpoint + id}/favorite/`, data);
}

export function editDirectoryFavorited(id, data) {
  return fetcher.post(`/api/v1/directories/${id}/favorite/`, data);
}

// SHARE
export function shareDocument(id, data) {
  return fetcher.post(`${endpoint + id}/users/`, data);
}

export function shareDirectory(id, data) {
  return fetcher.post(`/api/v1/directories/${id}/users/`, data);
}

// DIRECTORIES
export function fetchDirectoriesList() {
  return fetcher.get('/api/v1/directories/');
}

export function fetchDirectoryDocumentsList(directoryId) {
  return fetcher.get(`api/v1/doc-composed/?directory=${directoryId}`);
}
export function fetchDirectoryFavoriteList(directoryId) {
  return fetcher.get(`api/v1/doc-favorites/?directory=${directoryId}`);
}
export function fetchDirectoryCompanyList(directoryId) {
  return fetcher.get(`api/v1/doc-company/?directory=${directoryId}`);
}
export function fetchDirectorySharedWithMeList(directoryId) {
  return fetcher.get(`api/v1/doc-shared-with-me/?directory=${directoryId}`);
}
export function fetchDirectorySharedByMeList(directoryId) {
  return fetcher.get(`api/v1/doc-shared-by-me/?directory=${directoryId}`);
}

export function createDirectory(data) {
  return fetcher.post('/api/v1/directories/', data);
}
export function deleteDirectory(id) {
  return fetcher.delete(`/api/v1/directories/${id}/`);
}

export function editDirectory(id, data) {
  return fetcher.patch(`/api/v1/directories/${id}/`, data);
}

// FAVORITED DOCS AND DIRS
export function fetchFavoritedList() {
  return fetcher.get('/api/v1/doc-favorites/');
}

// DOCUMENT AND DIR USERS
export function fetchDocumentUsers(documentId) {
  return fetcher.get(`${endpoint}${documentId}/users/`);
}

export function fetchDirectoryUsers(directoryId) {
  return fetcher.get(`api/v1/directories/${directoryId}/users/`);
}

export function fetchSharedByMeList() {
  return fetcher.get('api/v1/doc-shared-by-me/');
}

export function fetchSharedWithMeList() {
  return fetcher.get('api/v1/doc-shared-with-me/');
}
