/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import dayjs from 'dayjs';

import { Screen } from '@features/common';
import { SkeletonRenderer } from '@lib/skeleton-loader';
import { Trans } from '@lingui/macro';
import { Avatar, Typography, SelectBox } from '@oca/ui';
import { getFullName } from '@lib/help-fns';
import { PencilRuler, Person } from '@oca/icons';
import { statuses } from '../datas';

const assigneeAvailableStatuses = statuses.filter(
  status => status.value !== 'done',
);

export const ViewMeta = ({
  createdAt,
  creator,
  dueDate,
  isLoading,
  isOwner,
  onStatusChange,
  project,
  priority,
  status,
}) => {
  return (
    <Flex backgroundColor="greys.0" py={20} paddingLeft={4}>
      <Box width={1 / 3}>
        <Box mb={16}>
          <SkeletonRenderer
            width={140}
            height={14}
            loading={isLoading}
            render={() => (
              <Descriptor
                field={<Trans>Status</Trans>}
                render={() =>
                  status && (
                    <Box mb="-4px">
                      <Dropdown
                        options={isOwner ? statuses : assigneeAvailableStatuses}
                        value={statuses.find(item => item.value === status)}
                        onChange={item => onStatusChange(item.value)}
                      />
                    </Box>
                  )
                }
              />
            )}
          />
        </Box>
        <SkeletonRenderer
          width={140}
          height={14}
          loading={isLoading}
          render={() => (
            <Descriptor
              field={<Trans>Priority</Trans>}
              render={() => (
                <Typography lineHeight={1}>{priority || 'N/A'}</Typography>
              )}
            />
          )}
        />
      </Box>
      <Box width={1 / 3}>
        <Box mb={12}>
          <SkeletonRenderer
            width={180}
            height={14}
            loading={isLoading}
            render={() => (
              <Descriptor
                field={<Trans>Project</Trans>}
                render={() => (
                  <Flex alignItems="center" mb="-6px">
                    <Avatar
                      src={project ? project.avatar : undefined}
                      size={24}
                      mr={2}
                    >
                      <PencilRuler />
                    </Avatar>
                    <Typography noWrap>
                      {project ? project.name : '---'}
                    </Typography>
                  </Flex>
                )}
              />
            )}
          />
        </Box>
        <SkeletonRenderer
          width={180}
          height={14}
          loading={isLoading}
          render={() => (
            <Descriptor
              field={<Trans>Creator</Trans>}
              render={() => (
                <Flex alignItems="center" mb="-6px">
                  <Avatar
                    src={creator ? creator.avatar : undefined}
                    size={24}
                    mr={2}
                  >
                    <Person />
                  </Avatar>
                  <Typography noWrap>
                    {creator
                      ? getFullName(creator, { middleKey: null })
                      : '---'}
                  </Typography>
                </Flex>
              )}
            />
          )}
        />
      </Box>
      <Box width={1 / 3}>
        <Box mb={3}>
          <SkeletonRenderer
            width={100}
            height={14}
            loading={isLoading}
            render={() => (
              <Descriptor
                field={<Trans>Due date</Trans>}
                render={() => (
                  <React.Fragment>
                    <Typography lineHeight={1} mr={2} noWrap>
                      <ResponsiveDate
                        date={dueDate}
                        compactFormat="MMM dd, yyyy hh:mm aaa"
                        standardFormat="MMMM dd, yyyy hh:mm aaa"
                      />
                    </Typography>
                    <Box mb={-1}>
                      <Overdue time={dueDate} status={status} />
                    </Box>
                  </React.Fragment>
                )}
              />
            )}
          />
        </Box>
        <SkeletonRenderer
          width={100}
          height={14}
          loading={isLoading}
          render={() => (
            <Descriptor
              field={<Trans>Created</Trans>}
              render={() => (
                <Typography lineHeight={1} noWrap>
                  <ResponsiveDate
                    date={createdAt}
                    compactFormat="MMM dd, yyyy hh:mm aaa"
                    standardFormat="MMMM dd, yyyy hh:mm aaa"
                  />
                </Typography>
              )}
            />
          )}
        />
      </Box>
    </Flex>
  );
};

ViewMeta.propTypes = {
  // eslint-disable-next-line react/require-default-props
  creator: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  project: PropTypes.shape({
    avatar: PropTypes.string,
    id: PropTypes.number,
  }),
  // eslint-disable-next-line react/require-default-props
  status: PropTypes.string,
};

ViewMeta.defaultProps = {
  isLoading: false,
};

// eslint-disable-next-line react/prop-types
const Descriptor = ({ field, render, ...rest }) => {
  return (
    <Flex alignItems="flex-end" {...rest}>
      <Typography color="textSecondary" fontSize={12} lineHeight={1} mr={2}>
        {field}:
      </Typography>
      {render()}
    </Flex>
  );
};

function ResponsiveDate({
  date,
  compactFormat = 'ddd ll',
  standardFormat = 'dddd LL',
}) {
  if (date) {
    return (
      <Screen>
        {({ xsmall }) =>
          dayjs(new Date(date)).format(xsmall ? compactFormat : standardFormat)
        }
      </Screen>
    );
  }

  return '---';
}

function Overdue({ status, time }) {
  return (
    dayjs().isAfter(new Date(time)) &&
    ['todo', 'in_progress'].includes(status) && (
      <Text
        padding={1}
        fontSize={12}
        color="white"
        bg="error"
        style={{ borderRadius: 4 }}
      >
        <Trans>overdue</Trans>
      </Text>
    )
  );
}

function Dropdown(props) {
  return (
    <SelectBox
      {...props}
      styles={{
        dropdownIndicator: base => ({ ...base, padding: 0 }),
        valueContainer: base => ({ ...base, padding: '0 8px' }),
        input: base => ({
          ...base,
          fontSize: 14,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        control: base => ({
          ...base,
          width: 110,
          height: 20,
          minHeight: 20,
          'border-color': 'transparent ',
        }),
        indicatorSeparator: () => ({ visibility: 'hidden ' }),
      }}
      menuPortalTarget={document.body}
      getOptionValue={option => option.value}
      getOptionLabel={option => (
        <Typography color="inherit" lineHeight="15px">
          {getStatusLabel(option.value)}
        </Typography>
      )}
    />
  );
}

function getStatusLabel(status) {
  const founded = statuses.find(({ value }) => value === status);

  return founded ? founded.label : 'N/A';
}
