import React from 'react';
import { SvgIcon } from './svg-icon';

export const Folder = props => (
  <SvgIcon {...props} viewBox="0 0 36 26">
    <path
      d="M14.667 0.5H3.83328C1.99977 0.5 0.5 1.99984 0.5 3.83336V22.1666C0.5 24.0002 1.99977 25.5 3.83328 25.5H32.1667C34.0002 25.5 35.5 24.0002 35.5 22.1666V7.58336C35.5 5.74984 34.0002 4.25 32.1667 4.25H18.0005L14.667 0.5Z"
      // fill="#88909D"
    />
  </SvgIcon>
);
