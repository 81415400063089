import React from 'react';
import { t } from '@lingui/macro';

import { Documents } from '@oca/icons';
import { i18n } from '@lib/i18n';
import { DocumentsPages } from './documentsPages';

export const routes = [
  {
    path: '/documents',
    component: DocumentsPages,
    navigation: { name: i18n._(t`Documents`), icon: <Documents /> },
    routes: [
      {
        path: '/documents/files',
        navigation: { name: i18n._(t`My Files`), onlyTopLevelLink: true },
        exact: true,
      },
      {
        path: '/documents/starred',
        navigation: { name: i18n._(t`Starred`), onlyTopLevelLink: true },
        exact: true,
      },
      {
        path: '/documents/shared-with-me',
        navigation: {
          name: i18n._(t`Shared with me`),
          onlyTopLevelLink: true,
        },
        exact: true,
      },
      {
        path: '/documents/shared-by-me',
        navigation: {
          name: i18n._(t`Shared by me`),
          onlyTopLevelLink: true,
        },
        exact: true,
      },
      {
        path: '/documents/company-files',
        navigation: {
          name: i18n._(t`Company Files`),
          onlyTopLevelLink: true,
        },
        exact: true,
      },
    ],
  },
];
