import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconButton } from '../atoms/icon-button';
import { ListItem } from './list-item';
import { Progress } from '../atoms/progress';

export const UploadItem = React.forwardRef(
  /**
   * @param {Object} props
   * @param {number} [props.progress] Uploading progress value
   * @param {string | React.ReactNode} [props.statusText] Status text
   * @param {Function} [props.renderActions]
   * @param {React.Ref} ref
   */
  function UploadItem(props, ref) {
    const { id, progress, statusText, renderActions, title } = props;

    return (
      <Wrapper ref={ref}>
        <ListItem
          as="div"
          paddingBottom={0}
          actions={renderActions ? renderActions(id) : null}
          textContainerOverflow
        >
          <ListItem.Text noWrap>{title}</ListItem.Text>
          <ListItem.Text variant="secondary">{statusText}</ListItem.Text>
        </ListItem>
        {progress !== null && (
          <Progress variant="determinate" value={progress} />
        )}
      </Wrapper>
    );
  },
);

UploadItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  progress: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  renderActions: PropTypes.func,
  statusText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

UploadItem.defaultProps = {
  progress: null,
  statusText: '',
  title: null,
};

UploadItem.Action = UploadItemAction;

const Wrapper = styled.div`
  position: relative;
  padding: 0 16px 8px;
  margin-bottom: 8px;
  background: #f7f7f7;
  border-radius: 4px;
`;

function UploadItemAction(props) {
  return <IconButton {...props} />;
}

UploadItemAction.defaultProps = {
  color: 'text',
  size: 'small',
  padding: '8px',
};
