import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { CheckedCheckbox, CheckboxIndeterminate } from '@oca/icons';

export const Checkbox = React.forwardRef(
  /**
   *
   * @param {CheckboxProps} props
   * @param {import('react').Ref} ref
   */
  function Checkbox(props, ref) {
    const {
      as,
      checked,
      disabled,
      indeterminate,
      label,
      name,
      onChange,
      readOnly,
      tabIndex,
      value,
    } = props;

    return (
      <Label as={as} disabled={disabled}>
        <Input
          type="checkbox"
          disabled={disabled}
          name={name}
          tabIndex={tabIndex}
          checked={checked}
          onChange={onChange}
          ref={ref}
          readOnly={readOnly}
          value={value}
        />
        <IconContainer alignItems="center" justifyContent="center">
          {checked && !indeterminate && <CheckedCheckbox />}
          {checked && indeterminate && <CheckboxIndeterminate />}
        </IconContainer>
        {label && (
          <Text color="text" fontSize={1} lineHeight="20px" marginLeft={2}>
            {label}
          </Text>
        )}
      </Label>
    );
  },
);

Checkbox.propTypes = {
  // eslint-disable-next-line react/require-default-props
  as: PropTypes.node,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  indeterminate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  indeterminate: false,
  label: null,
  name: '',
  readOnly: false,
  tabIndex: 0,
};

const IconContainer = styled(Flex)`
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 2px solid ${({ theme }) => theme.colors.textSecondary};
  & > svg {
    position: absolute;
    visibility: hidden;
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: inherit;
  &:checked + ${IconContainer} {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    & > svg {
      visibility: visible;
    }
  }
  &:focus + ${IconContainer},${IconContainer}:hover {
    border-color: ${({ theme }) => theme.colors.blues[5]};
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fontFamily};
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: self-start;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  ${IconContainer} {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.disabled : theme.colors.textSecondary};
  }
  &:hover ${IconContainer} {
    border-color: ${({ disabled, theme }) => !disabled && theme.colors.text};
  }
`;

/**
 * @typedef {Object} CheckboxProps
 * @property {Boolean} checked
 * @property {Boolean} disabled
 * @property {Boolean} indeterminate
 * @property {import('react').ReactNode} label
 * @property {string} label
 * @property {Function} onChange
 * @property {string | number} tabIndex
 * @property {Boolean} readOnly
 */
